
/**
 * Index-classic-saas component
 */
import { CheckCircleIcon, XCircleIcon } from 'vue-feather-icons'

import omiseService from "@/services/omiseService";
import accountMenu from "@/views/account/menu.vue";
export default {
    components: {
        CheckCircleIcon,
        XCircleIcon,
        accountMenu,
    },
    data: () => ({
        headers: [
            {
                text: "Invoice No.",
                align: "center",
                sortable: false,
                value: "invoiceNo",
            },
            {
                text: "Date",
                align: "center",
                sortable: false,
                value: "created_at",
            },
            {
                text: "Spec",
                align: "center",
                sortable: false,
                value: "specs",
            },
            {
                text: "Amount (THB)",
                align: "right",
                sortable: false,
                value: "amount",
            },
            {
                text: "Status",
                align: "center",
                sortable: false,
                value: "paid",
            },
            {
                text: "Action",
                align: "center",
                sortable: false,
                value: "action",
            },
          ],
        billingHistory: [],
    }),
    mounted() {
        this.getBillingHistory();
    },
    methods: {
        getBillingHistory() {
            omiseService
            .listBillingHistory()
            .then((resp) => {
                if (resp.data.status == true && resp.data.result != null) {
                    this.billingHistory = resp.data.result;
                    delete resp.data.result;
                } else {
                    throw "Can not get customer's cards";
                }
            })
            .catch((err) => {
                this.billingHistory = [];
            });
        },
    },
};
