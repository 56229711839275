var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-12"},[_c('accountMenu')],1),_c('div',{staticClass:"col-xl-9 col-lg-9 col-md-9 col-sm-12"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h3',{staticClass:"title"},[_vm._v("Billing")]),_c('router-link',{staticClass:"btn btn-outline-primary ml-2",attrs:{"to":"/calculator"}},[_vm._v("Update Plan")])],1),_c('p',{staticClass:"border-bottom mt-2"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"table-responsive bg-white"},[_c('v-data-table',{staticClass:"table table-center table-padding mb-0",attrs:{"headers":_vm.headers,"items-per-page":10,"items":_vm.billingHistory},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.specs",fn:function(ref){
var item = ref.item;
return [_c('p'),_c('p',[_vm._v("User: "+_vm._s(item.specs.user))]),_c('p',[_vm._v("Storage: "+_vm._s(item.specs.storage))]),_c('p',[_vm._v("Live: "+_vm._s(item.specs.live))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numFormat")(item.amount,"0,0.00"))+" ")]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [(item.paid)?_c('div',{staticClass:"badge badge-custom"},[_c('check-circle-icon',{staticClass:"mr-2",attrs:{"size":"1.5x"}}),_vm._v("paid ")],1):_c('div',{staticClass:"badge badge-custom2"},[_c('x-circle-icon',{staticClass:"mr-2",attrs:{"size":"1.5x"}}),_vm._v("failed ")],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"btn btn-outline-primary",attrs:{"href":"#"}},[_vm._v(" More ")])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }